import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Checkbox from 'components/shared/checkbox';
import Container from 'components/shared/container';
import Field from 'components/shared/field';
import FormButton from 'components/shared/form-button';
import Heading from 'components/shared/heading';

const FORM_NAME = 'saas-activation';
const BUTTON_SUCCESS_TIMEOUT_MS = 1.5 * 1000;

const today = new Date();
today.setHours(0, 0, 0, 0);
const validationSchema = yup.object().shape({
  pocName: yup.string().required('Name is required'),
  pocEmail: yup.string().email('Must be a valid email').required('Email required'),
  company: yup.string().required('Company is required'),
  brand: yup.string().optional(),
  agency: yup.string().optional(),
  campaignStartDate: yup
    .date()
    .min(today, 'Start date must be after today')
    .required('Start date is required')
    .typeError('Start date must be a valid date'),
  campaignDurationWeeks: yup
    .number()
    .required('Est. duration is required')
    .typeError('Est. duration must be a number'),
  campaignBudget: yup
    .number()
    .required('Est. budget is required, if unknown please enter n/a')
    .typeError('Est. budget must be a number'),
  campaignBudgetAlloc: yup
    .number()
    .min(1, 'Must be at least 1')
    .max(100, 'Cannot be more than 100')
    .typeError('Must be a number between 1 and 100')
    .optional(),
});

const ActivationForm = ({ token }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const [buttonState, setButtonState] = useState('default');
  const [done, setDone] = useState(false);
  const [formError, setFormError] = useState(null);

  function onSubmit(values) {
    setButtonState('loading');
    setFormError(null);

    fetch(`https://yobi-sas-webhook.azurewebsites.net/api/activation-form?token=${token}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'form-name': FORM_NAME,
        ...values,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not OK');
        }
        setButtonState('success');
        setTimeout(() => {
          setButtonState('default');
        }, BUTTON_SUCCESS_TIMEOUT_MS);
        setDone(true);

        // it's pretty safe at this point to expect yopix to be loaded and to throw an exception even if it isn't, but
        // for the sake of a clean console, let's check first
        if (window.yopix) {
          window.yopix('event', 'saas-activation-form-submitted', values);
        }
        navigate('/saas/activate/success');
      })
      .catch((error) => {
        setFormError(error);
        setButtonState('default');
      });
  }

  return (
    <section id="saas-activation">
      <Container className="w-1/2">
        {!done && (
          <div>
            <Heading className="text-center" tag="h2" size="lg" theme="primary">
              Activate your Yobi Registration
            </Heading>
            <form className="mt-8" name={FORM_NAME} method="post" onSubmit={handleSubmit(onSubmit)}>
              <Field
                id="activation-form-poc-name"
                label="Point of contact name"
                labelPosition="inline"
                type="text"
                placeholder="PoC name"
                theme="primary"
                className="w-1/2"
                error={errors?.pocName?.message}
                {...register('pocName')}
              />

              <Field
                id="activation-form-poc-email"
                label="Point of contact email"
                labelPosition="inline"
                type="email"
                placeholder="PoC email"
                theme="primary"
                className="w-1/2"
                error={errors?.pocEmail?.message}
                {...register('pocEmail')}
              />

              <Field
                id="activation-form-company"
                label="Company"
                labelPosition="inline"
                type="text"
                placeholder="Company"
                theme="primary"
                className="w-1/2"
                error={errors?.company?.message}
                {...register('company')}
              />

              <Field
                id="activation-form-brand"
                label="Brand (if applicable)"
                labelPosition="inline"
                type="text"
                placeholder="Brand"
                theme="primary"
                className="w-1/2"
                error={errors?.brand?.message}
                {...register('brand')}
              />

              <Field
                id="activation-form-agency"
                label="Agency"
                labelPosition="inline"
                type="text"
                placeholder="Agency"
                theme="primary"
                className="w-1/2"
                error={errors?.agency?.message}
                {...register('agency')}
              />

              <Field
                id="activation-form-start-date"
                label="Est. start date of campaign"
                labelPosition="inline"
                type="text"
                placeholder="MM/DD/YYYY"
                theme="primary"
                className="w-1/2"
                error={errors?.campaignStartDate?.message}
                {...register('campaignStartDate')}
              />

              <Field
                id="activation-form-duration"
                label="Est. Duration of campaign (in weeks)"
                labelPosition="inline"
                type="text"
                placeholder="Est. Duration of campaign (in weeks)"
                theme="primary"
                className="w-1/2"
                error={errors?.campaignDurationWeeks?.message}
                {...register('campaignDurationWeeks')}
              />

              <Field
                id="activation-form-budget"
                label="Est. budget for campaign"
                labelPosition="inline"
                type="text"
                placeholder="10000"
                theme="primary"
                className="w-1/2"
                error={errors?.campaignBudget?.message}
                {...register('campaignBudget')}
              />

              <Field
                id="activation-form-budget-alloc"
                label="Estimated percent of budget allocated for programmatic"
                labelPosition="inline"
                type="text"
                placeholder="15"
                theme="primary"
                className="w-1/2"
                error={errors?.campaignBudgetAlloc?.message}
                {...register('campaignBudgetAlloc')}
              />

              <Checkbox
                id="activation-form-has-1pd-from-crm"
                name="activation-form-first-party-data-options"
                label="Check this box if you have 1st party data available from a CRM to support audience modeling?"
                theme="primary"
                className="mb-4"
                value="has1pdFromCRM"
                {...register('firstPartyDataOptions')}
              />

              <Checkbox
                id="activation-form-has-1pd-from-dsp"
                name="activation-form-first-party-data-options"
                label="Check this box if you have 1st party data available from DSP log files to support audience modeling?"
                theme="primary"
                className="mb-4"
                value="has1pdFromDSP"
                {...register('firstPartyDataOptions')}
              />

              <Container className="relative w-full">
                <FormButton state={buttonState} theme="primary" className="justify-center">
                  Submit
                </FormButton>
              </Container>

              {formError?.message && (
                <Container className="relative w-full">
                  <p className="font-medium text-secondary-2">{formError.message}</p>
                </Container>
              )}
            </form>
          </div>
        )}
        {done && (
          <div>
            <Heading tag="h3" size="lg" theme="primary" className="text-center">
              Thank you
            </Heading>
            <p className="mt-4 text-center text-gray-3">We will be in touch with you shortly.</p>
          </div>
        )}
      </Container>
    </section>
  );
};

ActivationForm.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ActivationForm;
