import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import React from 'react';

import ActivationForm from 'components/pages/saas/activate/activation-form/activation-form';
import AuthError from 'components/shared/auth/error';
import AuthLoading from 'components/shared/auth/loading';
import Container from 'components/shared/container';
import BasicLayout from 'layouts/basic';
import { loginRequest } from 'utils/msal/auth-config';
import SeoMetadata from 'utils/seo-metadata';

const ActivatePage = () => {
  const isBrowser = () => typeof window !== 'undefined';
  let token = '';
  if (isBrowser()) {
    // ensure we have a token in the querystring
    const urlParams = new URLSearchParams(window.location.search);
    // since this is going back into the URL, it needs to be URL encoded
    token = encodeURIComponent(urlParams.get('token'));
  }
  if (token === '') {
    return (
      <BasicLayout pageMetadata={SeoMetadata.saas.activate.index}>
        <Container className="w-1/2">
          Invalid request. Did you arrive here from the Azure Marketplace?
        </Container>
      </BasicLayout>
    );
  }

  return (
    <BasicLayout pageMetadata={SeoMetadata.saas.activate}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={AuthError}
        loadingComponent={AuthLoading}
      >
        <ActivationForm token={token} />
      </MsalAuthenticationTemplate>
    </BasicLayout>
  );
};

export default ActivatePage;
