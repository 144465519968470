import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';

const AuthError = ({ error }) => (
  <Container className="text-center">An Error Occurred: {error.errorCode}</Container>
);

AuthError.propTypes = {
  error: PropTypes.shape({
    errorCode: PropTypes.string.isRequired,
  }),
};

AuthError.defaultProps = {
  error: {
    errorCode: 'unknown',
  },
};

export default AuthError;
