import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const commonClassNames =
  'inline-flex font-bold !leading-none whitespace-nowrap rounded outline-none transition-colors duration-200';

const themeClassNames = {
  primary: 'text-black',
  secondary: 'text-white',
};

const Checkbox = forwardRef(({ id, className: additionalClassName, label, theme }, ref) => {
  const className = classNames(
    commonClassNames,
    theme && themeClassNames[theme],
    additionalClassName
  );

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div className={className}>
      <input id={id} type="checkbox" checked={checked} ref={ref} onChange={handleChange} />
      {label && (
        <label className="ml-4" htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
});

Checkbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  theme: PropTypes.oneOf(Object.keys(themeClassNames)),
};

Checkbox.defaultProps = {
  id: null,
  className: null,
  label: null,
  theme: 'primary',
};

export default Checkbox;
